import React, { Component } from "react";

export class about extends Component {
  render() {
    let boxedContent1, boxedContent2, qualityContent, why1Content, why2Content, promiseContent;
    if (this.props.data) {
      const {
        technology,
        people,
        workforce,
        performance,
        quality,
        promise,
        Why,
        Why2,
      } = this.props.data;
      boxedContent1 = [technology, performance];
      boxedContent2 = [workforce, people];
      qualityContent = quality;
      why1Content = Why;
      why2Content = Why2;
      promiseContent = promise;
    }
    return (
      <div id="about">
        <div className="container">
          <div className="row">
            <div className="col-xs-12 col-md-6">
              {" "}
              <img
                src="img/about.jpg"
                className="img-responsive main-image"
                alt=""
              />{" "}
            </div>
            <div className="col-xs-12 col-md-6">
              <div className="about-text">
                <h2>About Us</h2>
                <p>
                  {this.props.data ? this.props.data.paragraph : "loading..."}
                </p>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="about-subsection mission">
              <h3>
                <span className="title-dash"></span>Our Mission
                <span className="title-dash"></span>
              </h3>
              <div className="mission-text">
                <p>
                  <i class="fas fa-quote-left"></i>{" "}
                  {this.props.data && this.props.data.mission}{" "}
                  <i class="fas fa-quote-right"></i>
                </p>
              </div>
            </div>
            <div className="about-subsection vision">
              <h3>
                <span className="title-dash"></span>Our Vision
                <span className="title-dash"></span>
              </h3>
              <div className="vision-text">
                <p>
                  <i class="fas fa-quote-left"></i>{" "}
                  {this.props.data && this.props.data.vision}{" "}
                  <i class="fas fa-quote-right"></i>
                </p>
              </div>
            </div>
            <div className="about-subsection values">
              <h3>
                <span className="title-dash"></span>
                Our Values, Principles, and Ethics
                <span className="title-dash"></span>
              </h3>
              <div className="values-text">
                <ul>
                  {this.props.data &&
                    this.props.data.values.map((value) => {
                      return (
                        <li>
                          <p>{value}</p>
                        </li>
                      );
                    })}
                </ul>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="about-subsection boxed-section1">
              {boxedContent1 &&
                boxedContent1.map((content) => {
                  return (
                    <div className="div col-md-6">
                      <div className="about-box">
                        <div className="title">
                          <h4>{content.title && content.title}</h4>
                        </div>
                        <div className="intro-text">
                          <p>{content.intro && content.intro}</p>
                        </div>
                        <div className="content-text">
                          <div>
                            <ul>
                              {content.content &&
                                content.content.map((content) => {
                                  return (
                                    <li>
                                      <p>
                                        <span
                                          style={{
                                            marginRight: "6px",
                                            color: "#ed9421",
                                          }}
                                        >
                                          <i class="far fa-check-square"></i>
                                        </span>
                                        {content}
                                      </p>
                                    </li>
                                  );
                                })}
                            </ul>
                          </div>
                        </div>
                      </div>
                    </div>
                  );
                })}
            </div>
          </div>
          <div className="row">
            <div className="about-subsection boxed-section2">
              {boxedContent2 &&
                boxedContent2.map((content) => {
                  return (
                    <div className="div col-md-6">
                      <div className="about-box about-box-small">
                        <div className="title">
                          <h4>{content.title && content.title}</h4>
                        </div>
                        <div className="intro-text">
                          <p>{content.intro && content.intro}</p>
                        </div>
                        <div className="content-text">
                          <div>
                            <ul>
                              {content.content &&
                                content.content.map((content) => {
                                  return (
                                    <li>
                                      <p>
                                        <span
                                          style={{
                                            marginRight: "6px",
                                            color: "#ed9421",
                                          }}
                                        >
                                          <i class="far fa-check-square"></i>
                                        </span>
                                        {content}
                                      </p>
                                    </li>
                                  );
                                })}
                            </ul>
                          </div>
                        </div>
                      </div>
                    </div>
                  );
                })}
            </div>
          </div>
          <div className="row">
            <div className="about-subsection capacity">
              <h3>
                <span className="title-dash"></span>Capacity
                <span className="title-dash"></span>
              </h3>
              <div
                className="capacity-text"
                dangerouslySetInnerHTML={
                  this.props.data && this.props.data.capacity
                }
              ></div>
            </div>
            <div className="about-subsection location">
              <h3>
                <span className="title-dash"></span>Business Location
                <span className="title-dash"></span>
              </h3>
              <div className="location-text">
                <p>{this.props.data && this.props.data.location}</p>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="about-subsection scope-coverage">
              <h3>Scope of Coverage</h3>
              <p>
                We provide our services to both local and international
                clientele.
              </p>
              <img
                src="/img/world-map.svg"
                className="img-responsive"
                alt="world map"
              />
            </div>
          </div>
          {/* quality subsection */}
          <div className="row">
            <div className="about-subsection quality-subsection">
              <div className="about-title">
                <h3>Our Quality Controls</h3>
                <div className="icon">
                  <h3>
                    <i className="fas fa-certificate"></i>
                  </h3>
                </div>
              </div>
              <div className="quality-text">
                <ul>
                  {qualityContent &&
                    qualityContent.map((content) => {
                      return (
                        <li>
                          <p>
                            <span
                              style={{
                                marginRight: "6px",
                                color: "#ed9421",
                              }}
                            >
                              <i class="far fa-check-square"></i>
                            </span>
                            {content}
                          </p>
                        </li>
                      );
                    })}
                </ul>
              </div>
            </div>
          </div>
          {/* why-us subsection */}
          <div className="row">
            <div className="about-subsection quality-subsection">
              <div className="about-title">
                <h3>Why Choose Us?</h3>
                <div className="icon">
                  <h3>
                    <i className="fas fa-crosshairs"></i>
                  </h3>
                </div>
              </div>
              <div className="quality-text">
                <div className="row">
                  <div className="col-md-6">
                    {why1Content &&
                      why1Content.map((content) => {
                        return Object.keys(content).map((key) => {
                          return (
                            <p>
                              <span
                                style={{
                                  marginRight: "6px",
                                  fontWeight: "600",
                                }}
                              >
                                {key}:
                              </span>
                              {content[key]}
                            </p>
                          );
                        });
                      })}
                  </div>
                  <div className="col-md-6">
                    {why2Content &&
                      why2Content.map((content) => {
                        return Object.keys(content).map((key) => {
                          return (
                            <p>
                              <span
                                style={{
                                  marginRight: "6px",
                                  fontWeight: "600",
                                }}
                              >
                                {key}:
                              </span>
                              {content[key]}
                            </p>
                          );
                        });
                      })}
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/* our promise section  */}
          <div className="row">
            <div className="about-subsection quality-subsection">
              <div className="about-title">
                <h3>Our Promise</h3>
                <div className="icon">
                  <h3>
                    <i class="fas fa-hands-helping"></i>
                  </h3>
                </div>
              </div>
              <div className="quality-text">
                <ul>
                  {promiseContent &&
                    promiseContent.map((content) => {
                      return (
                        <li>
                          <p>
                            <span
                              style={{
                                marginRight: "6px",
                                color: "#ed9421",
                              }}
                            >
                              <i class="far fa-check-square"></i>
                            </span>
                            {content}
                          </p>
                        </li>
                      );
                    })}
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default about;
