import React, { Component } from "react";

export class Services extends Component {
  render() {
    return (
      <div id="services">
        <div className="container">
          <div className="section-title text-center">
            <h2>Our Services</h2>
            <p>We offer a host of quality services.</p>
          </div>
          <div className="services-list">
            {this.props.data
              ? this.props.data.map((d, i) => (
                  <div key={`${d.name}-${i}`} className="service-item">
                    <div className="service-item-title text-center">
                      <h3>{d.name}</h3>
                    </div>
                    <div className="service-item-content">
                      <div className="row">
                        <div className="col-md-5">
                          <div className="service-item-icon">
                            <img
                              src={`img/services/${d.icon}`}
                              alt={d.name}
                              className="img-responsive"
                            />
                          </div>
                        </div>
                        <div className="col-md-7">
                          <div className="service-desc">
                            <div className="row">
                              <div
                                className={d.text2 ? "col-md-6" : "col-md-12"}
                              >
                                <ul>
                                  {d.text1 &&
                                    d.text1.map((t) => (
                                      <li>
                                        <p>
                                          <span style={{ marginRight: "6px" }}>
                                            <i class="far fa-check-square"></i>
                                          </span>
                                          {t}
                                        </p>
                                      </li>
                                    ))}
                                </ul>
                              </div>
                              {d.text2 ? (
                                <div className="col-md-6">
                                  <ul>
                                    {d.text2 &&
                                      d.text2.map((t) => (
                                        <li>
                                          {" "}
                                          <p>
                                            <span
                                              style={{ marginRight: "6px" }}
                                            >
                                              <i class="far fa-check-square"></i>
                                            </span>
                                            {t}
                                          </p>
                                        </li>
                                      ))}
                                  </ul>
                                </div>
                              ) : (
                                ""
                              )}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                ))
              : "loading"}
          </div>
        </div>
      </div>
    );
  }
}

export default Services;
